<template>
  <span :class="'text-' + theme" class="align-middle d-print-none">
    <div
      class="spinner-border text-center align-middle"
      :class="{
        'spinner-border-sm': isSm
      }"
      role="status"
    >
      <span class="sr-only">Updating...</span>
    </div>
    <span class="align-middle pl-2 d-print-none">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'CsLoading',
  props: {
    theme: {
      type: String,
      default: 'info'
    },
    sm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSm () {
      return this.sm || this.$attrs.sm
    }
  }
}
</script>

<style scoped></style>
